.Navbar__container {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    background-color: white;
    color : white;
    z-index: 101;
    padding: 0px 20px;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.175);
}