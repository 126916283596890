.WaterSlide__container {
    text-align: center;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    height: calc(100dvh - 60px);
    width: 100%;
    color : black;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

.WaterSlide__image, .WaterSlide__image2 {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    width: 30%;
    height: calc(100dvh - 60px);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.WaterSlide__content {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    width: 40%;
    height: calc(100dvh - 60px);
    background-color: white;
    font-size: 1.4rem;
}

.WaterSlide__content__text {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    width: 90%;
    max-width: 768px;
    height: 80%;
    background-color: white;
    font-size: 2rem;
}

.WaterSlide__content p {
    font-size: 1rem;
    margin: 20px 5px;
    text-align: justify;
}

.WaterSlide__content h2 {
    font-size: 3rem;
    font-weight: 600;
    margin: 40px;
    color: #52ae32;
    text-decoration: underline;
}

.WaterSlide__button {
    font-size: 1.3rem;
    font-weight: 600;
    margin: 5vh;
    padding: 15px 30px;
    background-color: #52ae32;
    border: 0;
    border-radius: 10px;
    color: white;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.WaterSlide__button:hover {
    background-color: #005fb6;
    color: white;
    transform: scale(1.05);
}

@media screen and (max-width: 1024px), (max-height: 700px) {
    
        .WaterSlide__image, .WaterSlide__image2 {
            display: block;
            width: 50%;
        }
        
        .WaterSlide__content {
            width: 50%;
        }
}

@media screen and (max-width: 1500px) {

    .WaterSlide__image, .WaterSlide__image2 {
        display: block;
        width: 25%;
    }
    
    .WaterSlide__content {
        width: 50%;
    }

}

@media screen and (max-width: 1200px) {

    .WaterSlide__image2 {
        width: 0%;
    }

    .WaterSlide__content {
        width: 75%;
    }
  
    .WaterSlide__content__h2 h2 {
        font-size: 2.5rem;
    }

    .WaterSlide__content__text p {
        margin: 8px;
    }

}

@media screen and (max-width: 1026px) {
    .WaterSlide__content__h2 h2 {
        font-size: 2.2rem;
    }

    .WaterSlide__content__text p {
        margin: 6px;
    }

    .WaterSlide__content__text {
        width: 90%;
    }
}

@media screen and (max-width: 950px) {
    .WaterSlide__content__h2 h2 {
        font-size: 3rem;
    }

    .WaterSlide__content__text {
        width: 70%;
    }

    .WaterSlide__content__text p {
        margin: 15px;
    }
}

@media screen and (max-width: 768px) {

    .WaterSlide__container {
        flex-direction: row;
        align-items: center;
        justify-content: center;
        height: 100%;
    } 

    .WaterSlide__content {
        flex-direction: column;
        margin: 0 auto;
    }

    .WaterSlide__content__text {
        width: 90%;
    }

    .WaterSlide__image {
        background-color: rgba(0, 0, 0, 0.75);
        width: 100dvw;
        height: 30%;
        display: block;
        position: absolute;
        margin: 0 auto;
        top: 0;
        left: 0;
        z-index: -1;
    }

    .WaterSlide__image2 {
        display: none;
    }

    .WaterSlide__content {
        font-size: 1rem;
        width: 90%;
        height: 100%;
        padding: 0;
        margin: 5px;
        background-color: transparent;
    }

    .WaterSlide__content__text {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: space-evenly;
        align-items: center;
        max-width: 100dvw;
        height: calc(70% - 20px);
        width: calc(100dvw - 40px);
        padding: 20px;
        position: absolute;
        top: 30%;
        left: 0px;
    }

    .WaterSlide__content__text p {
        font-size: 0.9rem;
        margin: 5px;
        text-align: justify;
    }

    .WaterSlide__button {
        margin: 4vh;
        font-size: 1.1rem;
    }

    .WaterSlide__content__h2 {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        font-size: 2rem;
        max-width: 100dvw;
        height: 30%;
        padding: 10px;
        position: absolute;
        top: 0%;
    }

    .WaterSlide__content__h2 h2 {
        font-size: 2.2rem;
        margin: 0;
        padding: 0;
        text-decoration: none;
    }

}