.SportSlide__container {
    text-align: center;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    height: calc(100dvh - 60px);
    width: 100%;
    color: black;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

.SportSlide__image {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    width: 40%;
    height: calc(100dvh - 60px);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.SportSlide__content {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    width: 60%;
    height: calc(100dvh - 60px);
    background-color: white;
    font-size: 1.4rem;
}

.SportSlide__content__text {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    width: 90%;
    max-width: 768px;
    height: calc(80% - 20px);
    background-color: white;
    font-size: 2rem;
}

.SportSlide__content p {
    font-size: 1rem;
    margin: 5px;
    text-align: justify;
}

.SportSlide__content h2 {
    font-size: 3rem;
    font-weight: 600;
    margin: 40px;
    color: #52ae32;
    text-decoration: underline;
}

@media screen and (max-height: 700px) {

    .SportSlide__content__text p {
        font-size: 0.9rem;
        margin: 5px;
        text-align: justify;
    }
    
}

@media screen and (max-width: 768px) {

    .SportSlide__container {
        flex-direction: row;
        align-items: center;
        justify-content: center;
        height: 100%;
    }

    .SportSlide__content {
        flex-direction: column;
        margin: 0 auto;
    }

    .SportSlide__image {
        background-color: rgba(0, 0, 0, 0.75);
        width: 100dvw;
        height: 30%;
        display: block;
        position: absolute;
        margin: 0 auto;
        top: 0;
        left: 0;
        z-index: -1;
    }

    .SportSlide__content {
        font-size: 1rem;
        width: 90%;
        height: 100%;
        padding: 0;
        background-color: transparent;
    }

    .SportSlide__content__text {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: space-evenly;
        align-items: center;
        max-width: 100dvw;
        height: calc(70% - 20px);
        padding: 10px;
        position: absolute;
        top: 30%;
    }

    .SportSlide__content__text p {
        font-size: 0.9rem;
        margin: 15px;
        text-align: justify;
    }

    .SportSlide__content__h2 {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        font-size: 2rem;
        max-width: 100dvw;
        height: 30%;
        padding: 0px;
        position: absolute;
        top: 0%;
    }

    .SportSlide__content__h2 h2 {
        font-size: 2.2rem;
        margin: 0;
        padding: 0;
        text-decoration: none;
    }

}