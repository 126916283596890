.Projects__container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    color: black;
}

.Projects__container__image {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    min-height: 35vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.Projects__container__image__cover {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 35vh;
    font-size: 1.4rem;
    z-index: 0;
}

.Projects__container__content__title {
    top: 60px;
    position: absolute;
    display: flex;
    width: 100%;
    height: 35vh;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    // Degradado de color negro
    background: linear-gradient(0deg, rgba(0,0,0,0.9) 0%, rgba(0,0,0,0.6) 100%);
    font-size: 1.4rem;
}

.Projects__container h1 {
    font-size: 60px;
    margin-bottom: 20px;
    color : white;
}

.Projects__container__content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    max-width: 1308px;
}

.Projects__container__content__projects {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: white;
    font-size: 1.4rem;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

.Projects__container__content__projects__project {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: white;
    font-size: 1.4rem;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
    padding: 10px;
}

.Projects__container__content__projects__project__image {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    width: 40%;
    height: auto;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.Projects__container__content__projects__project__image img {
    width: 100%;
    height: auto;
}

.Projects__container__content__projects__project__content {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    width: 60%;
    height: 100%;
    background-color: white;
    font-size: 1.4rem;
}

.Projects__container__content__projects__project__content__title {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 10%;
    background-color: white;
    font-size: 1.4rem;
    text-align: center;
}

.Projects__container__content__projects__project__content__text {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    text-align: center;
    align-items: center;
    width: 70%;
    max-width: 768px;
    height: 50%;
    background-color: white;
    font-size: 1.1rem;
}

.Projects__container__content__projects__project__content__text__button {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    width: 90%;
    max-width: 768px;
    height: 10%;
    background-color: white;
    font-size: 1.2rem;
}

.Projects__container__content__projects__project__content__text__button button {
    width: 50%;
    height: 50px;
    background-color: #52ae32;
    border: 1px solid #f5f5f5;
    border-radius: 20px;
    color: white;
    font-size: 1.2rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    margin: 20px;
}

.Projects__container__content__projects__project__content__text__button button:hover {
    background-color: #005fb6;
    transform: scale(1.05);
}

@media screen and (max-width: 768px) {

    .Projects__container__content__projects__project {
        flex-direction: column;
        border-bottom: 5px solid #f5f5f5;
        margin-bottom: 20px;
        box-shadow: none;
        padding-bottom: 0;
    }

    .reverse {
        flex-direction: column-reverse!important;
    }

    .Projects__container__content__projects__project__image {
        width: 90%;
    }
    .Projects__container__content__projects__project__content {
        width: 90%;
    }

    .Projects__container__content__projects__project__content__text {
        width: 90%;
        text-align: justify;
        text-justify: distribute-all-lines;
        line-break: normal;
        white-space: normal;
    }

    .Projects__container__content__projects__project__content__text__button button {
        width: 100%;
    }
}