.NavbarDesktop__container {
    text-align: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1308px;
    height: 60px;
    color: white;
    transition: height 0.3s ease-in-out;
}

.NavbarDesktop__logo img {
    height: 40px;
}

.NavbarDesktop__logo {
    display: flex;
    cursor: pointer;
}


.NavbarDesktop__menu {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    height: 100%;
    opacity: 1;
}

.NavbarDesktop__menu__item__container {
    display: flex;
    align-items: center;
    height: 100%;
    margin-left: 5px;
    padding: 0px 10px;
    cursor: pointer;
}

.NavbarDesktop__menu__item__container__active {
    color: #005fb6!important;
}

.NavbarDesktop__menu__item {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 20px;
    border-radius: 20px;

}

.NavbarDesktop__menu__item:hover a {
    color: #005fb6;
}

.NavbarDesktop__menu__item a {
    padding: 18px 0;
    text-decoration: none;
    font-weight: 700;
    color: aliceblue;
    font-size: 16px;
    color: #52ae32;
    transition: color 0.3s ease-in-out;
}

.NavbarDesktop__language__selector {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin-left: 15px;
    padding: 0px 10px;
}

.NavbarDesktop__language__selector img {
    height: 30px;
    border-radius: 50%;
    width: 30px;
    margin: 10px;
    cursor: pointer;
}

.NavbarDesktop__language__selector__flag__english{
    background-image: url("https://upload.wikimedia.org/wikipedia/commons/8/83/Flag_of_the_United_Kingdom_%283-5%29.svg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    margin: 10px;
    cursor: pointer;
}

.NavbarDesktop__language__selector__flag__spanish{
    background-image: url("https://upload.wikimedia.org/wikipedia/commons/9/9a/Flag_of_Spain.svg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    margin: 10px;
    cursor: pointer;
}

.NavbarDesktop__closed__hamburger {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.NavbarDesktop__open__hamburger {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

@media screen and (max-width: 960px) {

    .NavbarDesktop__menu__item__container {
        margin-left: 5px;
    }

    .NavbarDesktop__menu__item {
        padding: 0 13px;
    }

    .NavbarDesktop__language__selector {
        margin: 0;
        padding: 0 5px;
    }
}

@media screen and (max-width: 768px) {

    .NavbarDesktop__container {
        height: 60px;
        transition: all 0.3s ease-in-out;
    }

    .NavbarDesktop__container--open {
        position: absolute;
        top: 0;
        left: 0;
        font-size: larger;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        height: 100vh;
        width: 100%;
        background-color: white;
        color: black;
        box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
        z-index: 100;
    }

    .NavbarDesktop__container--open .NavbarDesktop__logo {
        margin-top: 10vh;
        margin-bottom: 20px;
        height: 90px;
        transition: all 0.5s ease-in-out;
    }

    .NavbarDesktop__container--open .NavbarDesktop__language__selector {
        visibility: visible;
    }

    .NavbarDesktop__container--open .NavbarDesktop__logo img {
        height: 60px;
        transition: all 0.3s ease-in-out;
    }

    .NavbarDesktop__container--open .NavbarDesktop__menu {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        height: 100%;
        width: 100%;

    }

    .NavbarDesktop__container--open .NavbarDesktop__menu {
        opacity: 1;
        visibility: visible;
        width: 100%;
        transition: width 0.6s ease-in-out;
    }

    .NavbarDesktop__menu {
        opacity: 0;
        visibility: hidden;
        width: 0;
        transition: width 0.6s ease-in-out;
    }

    .NavbarDesktop__container--open .NavbarDesktop__menu__item__container {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        height: 100%;
        width: 100%;
        padding: 0;
        margin: 0;
        transition: height 10s ease-in-out;
    }

    .NavbarDesktop__container--open .NavbarDesktop__menu__item {
        display: flex;
        visibility: visible;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        width: 100%;
        max-width: 50vw;
        max-height: 40px;
        padding: 30px 0;
        border-radius: 50px;
    }

    .NavbarDesktop__container--open .NavbarDesktop__menu__item a {
        display: flex;
        visibility: visible;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        height: 100%;
        width: 100%;
        text-decoration: none;
    }

    .NavbarDesktop__open__hamburger {
        display: none;
    }

    .NavbarDesktop__container--open .NavbarDesktop__open__hamburger {
        position: absolute;
        font-size: 20px;
        color: #005fb6;
        font-weight: 600;
        top: 30px;
        display: block;
        visibility: visible;
        opacity: 1;
        right: 40px;
        cursor: pointer;
        transition: opacity 0.3s ease-in-out;
    }

    .NavbarDesktop__container--open .NavbarDesktop__container__closed {
        width: 100%;
        height: 60px;
        display: flex;
        visibility: visible;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

    }

    .NavbarDesktop__container--open .NavbarDesktop__closed__logo img {
        height: 50px;
    }

    .NavbarDesktop__closed__hamburger {
        margin-right: 20px;
        height: 50px;
        display: flex;
        visibility: visible;
        opacity: 1;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;

    }

    .NavbarDesktop__container--open .NavbarDesktop__closed__hamburger {
        visibility: hidden;
        opacity: 0;
    }

    .NavbarDesktop__closed__hamburger__line {
        width: 30px;
        height: 4px;
        background-color: #005fb6;
        margin: 3px 0;

        visibility: visible;
    }


}