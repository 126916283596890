.ContactForm__container {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: transparent;
    color : black;
    width: 60%;
}

.ContactForm__container h3 {
    font-size: 4vmin;
    font-weight: 600;
    margin: 20px;
}

.ContactForm__upper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 1rem;
}

.ContactForm__upper input {
    margin: 10px;
    padding: 1.5rem;
    border: 1px solid #005fb6;
    border-radius: 30px;
    width: 50%;
    transition: all 0.3s ease-in-out;
}


input, textarea,
input::placeholder, textarea::placeholder {
    color: #005fb6;
    font-size: 1rem;
    font-weight: 500;
    font-family: 'Montserrat', sans-serif;
}

textarea::placeholder {
    padding: 10px;
}

input:invalid, textarea:invalid,
input:invalid:hover, textarea:invalid:hover,
input:invalid:focus, textarea:invalid:focus {
    border: 1px solid red;
    color: red;
}

input:hover::placeholder, textarea:hover::placeholder,
input:focus::placeholder, textarea:focus::placeholder {
    color: green;
}

input:hover:autofill, textarea:hover:autofill,
input:focus:autofill, textarea:focus:autofill,
input:active:autofill, textarea:active:autofill
{
    border: 1px solid green;
    font-size: 1rem;
    font-family: 'Montserrat', sans-serif;
}

input:focus-visible, textarea:focus-visible,
input:active, textarea:active,
input:hover, textarea:hover,
input:focus, textarea:focus {
    border: 1px solid green!important;
    color: green!important;
    outline: none;
}

.ContactForm__container label {
    display: none;
}

.ContactForm__terms {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    width: 100%;
}

input[type="checkbox"] {
    margin: 10px;
    width: 20px;
    height: 20px;
    border: 1px solid #005fb6;
    border-radius: 5px;
    transition: all 0.3s ease-in-out;
    max-width: 30px;
}

.ContactForm__terms label {
    margin: 10px;
    display: block;
    font-size: 1rem;
    font-weight: 500;
    text-align: justify;
}

.ContactForm__container form {
    display: flex;
    min-width: 40%;
    max-width: 90%;
    flex-direction: column;
    justify-content: space-between;
    background-color: white;
    align-items: center;
    padding: 2rem 3rem;
    border-radius: 10px;
}

.ContactForm__lower {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 1rem;
    margin-top: 0px;
}

.ContactForm__lower textarea {
    margin: 10px;
    padding: 1rem;
    border: 1px solid #005fb6;
    border-radius: 30px;
    width: 100%;
    height: 150px;
    resize: vertical;
}


.ContactForm__button {
    width: 80%;
    height: 50px;
    background-color: #52ae32;
    border: 1px solid #f5f5f5;
    border-radius: 20px;
    color: white;
    font-size: 1.2rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    margin: 10px;
}	

.ContactForm__button:hover {
    background-color: #005fb6;
    color: white;
    transform: scale(1.05);
}

@media screen and (max-width: 1024px) {
    .ContactForm__container {
        width: 100%;
    }

    .ContactForm__container form {
        width: 100%;
    }
    
}

@media screen and (max-width: 768px) {

    .ContactForm__container h3 {
        font-size: 2rem;
        margin: 0;
    }

    .ContactForm__upper, .ContactForm__lower {
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    input, textarea {
        width: 90%!important;
        padding: 1.5rem!important;
    }

    textarea {
        margin: 0!important;
    }

}
