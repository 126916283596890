.Footer__container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    height: 100%;
    color: #005fb6;
    padding: 50px 0;
    position: relative;
    bottom: 0;
}

.Footer__container__left,
.Footer__container__right {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 40%;
    height: 100%;
    padding: 0 20px;
}

.Footer__container__right {
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 100%;
    color: black;
    font-size: 1.2rem;
    font-weight: 600;
}

.Footer__container__right__logos {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.Footer__container__left__logo {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 100%;
    padding: 0 20px;
}

.instagram {
    background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
    color: white;
    border-radius: 30px;
}

.Footer__container__right__logo {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 25px;
    padding: 0 10px;
    cursor: pointer;
}

.Footer__container__left__logo img,
.Footer__container__right__logos svg {
    display: block;
    width: 100%;
    height: 100%;
    max-width: 60px;
    max-height: 60px;
}