.Home__container {
    text-align: center;
    display: flex;
    scroll-snap-type: y mandatory;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    min-height: calc(100dvh - 60px);
    color : white;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

.swiper-container {
    width: 100%;
    height: 100%;
}

.swiper {
    width: 100%;
    height: calc(100dvh - 60px);
}