.EducationSlide__container {
    text-align: center;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    height: calc(100dvh - 60px);
    width: 100%;
    color: black;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

.EducationSlide__image {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    width: 40%;
    height: calc(100dvh - 60px);
    background-repeat: no-repeat;
    background-size: cover;
    //background-position: center;
}

.EducationSlide__content {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    width: 60%;
    height: calc(100dvh - 60px);
    background-color: white;
    font-size: 1.4rem;
}

.EducationSlide__content__text {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    width: 90%;
    max-width: 768px;
    height: calc(70% - 20px);
    background-color: white;
    font-size: 2rem;
}

.EducationSlide__content p {
    font-size: 1rem;
    margin: 5px;
    text-align: justify;
}

.EducationSlide__content h2 {
    font-size: 3rem;
    font-weight: 600;
    margin: 0px 20px;
    margin-bottom: 40px;
    color: #52ae32;
    text-decoration: underline;
}

.EducationSlide__content__quote__container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 30%;
}

.EducationSlide__content__quote {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 90%;
    margin: 5px auto;
}

@import url(https://fonts.googleapis.com/css?family=Open+Sans:400italic);

blockquote {
    font-family: 'Open Sans', sans-serif;
    font-size: 1rem;
    font-style: italic;
    font-weight: 400;
    line-height: 1.5;
    margin: 20px;
    padding: 10px 20px;
    background-color: #F0f0f0;
    border-left: 8px solid #52ae32;
}

blockquote span {
    display: block;
    color: rgba($color: #005bf6, $alpha: 1);
    font-style: normal;
    font-weight: bold;
    position: relative;
    font-size: 0.95rem;
    margin-top: 10px;
}

@media screen and (max-width: 1024px) {
    


}

@media screen and (max-width: 768px) {

    .EducationSlide__container {
        flex-direction: row;
        align-items: center;
        justify-content: center;
        height: 100%;
    }

    .EducationSlide__content {
        flex-direction: column;
        margin: 0 auto;
    }

    .EducationSlide__image { 
        background-color: rgba(0, 0, 0, 0.75);
        width: 100dvw;
        height: 30%;
        display: block;
        position: absolute;
        margin: 0 auto;
        top: 0;
        left: 0;
        z-index: -1;
    }

    .EducationSlide__content {
        font-size: 1rem;
        width: 90%;
        height: 100%;
        padding: 0;
        background-color: transparent;
    }

    .EducationSlide__content__text {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: space-evenly;
        align-items: center;
        max-width: 100dvw;
        height: calc(70% - 20px);
        padding: 10px;
        position: absolute;
        top: 30%;
    }

    .EducationSlide__content__text p {
        font-size: 0.9rem;
        margin: 5px;
        text-align: justify;
    }

    .EducationSlide__content__h2 {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        font-size: 2rem;
        max-width: 100dvw;
        height: 30%;
        padding: 0px;
        position: absolute;
        top: 0%;
    }

    .EducationSlide__content__h2 h2 {
        font-size: 2.2rem;
        margin: 0;
        padding: 0;
        text-decoration: none;
    }

    .EducationSlide__content__quote__container {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 50%;
    }

    blockquote {
        width: calc(100dvw - 60px);
        height: fit-content;
        margin: 10px 0;
        font-size: 1rem;
        padding: 10px;
        padding-left: 10px;
        overflow: hidden;
    }
    
    blockquote span {
        display: block;
        color: rgba($color: #005bf6, $alpha: 0.9);
        font-style: normal;
        font-weight: bold;
        position: relative;
        font-size: 1.1rem;
        margin-top: 10px;
    }

    .quoteSwiper {
        height: 100%;
        min-height: 25vh;
    }

}