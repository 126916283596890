.Contact__container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: center;
    color: white;
}

.Contact__container h1 {
    font-size: 50px;
    margin: 20px 0;
    color : black;
    text-align: center;
}

.Contact__container__content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    max-width: 1308px;
}

.Contact__container__map {
    height: 40vh;
    width: 100%;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

.leaflet-container {
    height: 100%;
    width: 100%;
}

@media screen and (max-width: 768px) {
    .Contact__container {
        flex-direction: column-reverse;
    }
}