.ContactMedium__container {
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap-reverse;
    align-items: center;
    padding: 0 20px;
    height: 100%;
    width: 30%;
    color : black;
}

.ContactMedium__container__item {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 100%;
    min-width: 70%;
    padding: 10px;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
    transition: all 0.3s ease-in-out;
    margin: 10px 0;
    cursor: pointer;
}

.ContactMedium__container__item:hover {
    transform: scale(1.1);

    svg {
        color: #005fb6;
        width: 60px;
        height: 60px;
    }
}

.ContactMedium__container__item svg {
    transition: all 0.3s ease-in-out;
    display: block;
    color: #52ae32;
    height: 50px;
    width: auto;
    margin: 0 auto;
}

.ContactMedium__container__item a {
    text-decoration: none;
    color: black;
}

@media screen and (max-width: 1024px) {
    .ContactMedium__container {
        width: 100%;
    }
}