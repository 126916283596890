.About__container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    color: black;
}

.About__container__image {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    min-height: 35vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.About__container__image__cover {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 35vh;
    font-size: 1.4rem;
    z-index: 0;
}

.About__container__content__title {
    top: 60px;
    position: absolute;
    display: flex;
    width: 100%;
    height: 35vh;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    // Degradado de color negro
    background: linear-gradient(0deg, rgba(0,0,0,0.9) 0%, rgba(0,0,0,0.6) 100%);
    font-size: 1.4rem;
}

.About__container h1 {
    font-size: 60px;
    margin-bottom: 20px;
    color : white;
}

.About__container__content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    max-width: 1308px;
}

@media screen and (max-width: 768px) {
    .About__container h1 {
        font-size: 40px;
    }
}