.Slide1__container {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    height: 100%;
    width: 100%;
    color : white;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
    background-position: left;
    background-repeat: no-repeat;
    background-size: cover;
}

.Slide1__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    font-size: 1.4rem;
}

.Slide1__content h1 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    font-size: 5rem;
    transition: all 0.5s ease-in-out;
}

.Slide1__title__span__save {
    color: #005fb6;
    margin-right: 20px;
    transition: all 0.5s ease-in-out;
}

.Slide1__title__span__save:hover, .Slide1__title__span__solidarity:hover {
    transform: scale(1.2);
}

.Slide1__title__span__solidarity {
    color: #52ae32;
    transition: all 0.5s ease-in-out;
}

.Slide1__content p {
    font-size: 2rem;
    margin: 5px;
    text-align: center;
    max-width: 1308px;
    width: 80%;
}

@keyframes Slide1__down__arrow__animation {
    0% {
        transform: translateY(0);
        color: #005fb6;
    }
    50% {
        transform: translateY(10px);
        color: #52ae32;
    }
    100% {
        transform: translateY(0);
        color: #005fb6;
    }
}

.Slide1__down__arrow {
    position: absolute;
    padding: 20px 25px;
    border: 2px solid transparent;
    border-radius: 50%;
    bottom: 20px;
    left: calc(50% - 40px);
    transform: translateX(-50%);
    animation: Slide1__down__arrow__animation 3s infinite;
    font-size: 30px;
    font-weight: 900;
    transition: all 0.5s ease-in-out;
    cursor: pointer;
    line-height: 1;
}

.Slide1__down__arrow:hover {
    transform: scale(1.2);
    border: 2px solid;
}

@media screen and (max-width: 768px) {

    .Slide1__content {
        justify-content: flex-start;
        padding-top : 20px;
    }

    .Slide1__content h1 {
        font-size: 3.5rem;
        margin-bottom: 20px;
    }
    .Slide1__content p {
        font-size: 1.3rem;
    }

    .Slide1__down__arrow {
        transform: scale(1.2);
        border: 2px solid;
        padding: 18px 25px;
        left: calc(50% - 35px);
    }
}
