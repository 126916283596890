.PeopleContainer__container {
    text-align: center;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    background-color: white;
    color : black;
    max-width: 1308px;
    width: 100%;
}

.PeopleContainer__content {
    margin: 50px 0;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.PeopleContainer__content__title {
    font-size: 1.8rem;
    font-weight: 600;
}

.PeopleContainer__content__people {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 768px) {
    .PeopleContainer__content__title {
        font-size: 1.4rem;
    }
}