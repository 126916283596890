.PersonCard__container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    width: 20%;
    min-width: 20vmin;
    background-color: white;
    color : black;
    border: 0;
    padding: 20px 0;
    transition: box-shadow 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    margin: 20px 0px;
}

.PersonCard__container:hover {
    transform: scale(1.05);
    box-shadow: 0 0 10px rgba(0,0,0,0.5);
}

.PersonCard__content {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    width: 90%;
    height: 200px;
}

.PersonCard__image {
    width: 90%;
    max-height: 400px;
    height: 400px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.PersonCard__button {
    max-width: 150px;
    width: 100%;
    height: 50px;
    background-color: #52ae32;
    border: 1px solid #f5f5f5;
    border-radius: 20px;
    color: white;
    font-size: 1.2rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    margin-bottom: 10px;
}	

.PersonCard__button:hover {
    background-color: #005fb6;
    color: white;
    transform: scale(1.05);
}

.PersonCard__position {
    height: 70px;
}

@media screen and (max-width: 1308px) {
    .PersonCard__container {
        width: 24%;
    }
}

@media screen and (max-width: 1000px) {
    .PersonCard__container {
        width: 40%;
    }
}

@media screen and (max-width: 800px) {
    .PersonCard__container {
        width: 50%;
    }
}

@media screen and (max-width: 600px) {
    .PersonCard__container {
        width: 100%;
    }
}
