.Slide2__container {
    text-align: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 100%;
    background-color: white;
    color : white;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

.Slide2__content {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0 20px;
}

.Slide2__content__element {
    color: #005fb6;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0 20px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    //transition: scale 0.4s ease-in-out;
    background-image: none;
}

.Slide2__content__element:hover {
    background-color: rgba($color: #52ae32, $alpha: 0.9);
    // transition: color 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
    color: white;
}

.Slide2__content__element:hover .Slide2__content__element__icon {
    transform: scale(1.5);
    transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.Slide2__content__element:hover .Slide2__content__element__text {
    font-size: 1.5rem;
    transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1)
}

.Slide2__content__element__icon {
    font-size: 50px;
    margin-bottom: 20px;
}

.Slide2__content__element__text {
    font-size: 20px;
    text-align: center;
}

@media screen and (max-width: 768px) {
    .Slide2__container { 
        padding: 0;
        width: 100%;
    }

    .Slide2__content {
        flex-direction: column;
        width: 100%;
    }

    .Slide2__content__element__text {
        font-size: 16px;
        text-align: center;
    }

    .Slide2__content__element__icon {
        font-size: 40px;
        margin-bottom: 20px;
    }

    .Slide2__content__element:hover .Slide2__content__element__icon {
        transform: scale(1);
        transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
    }
}